import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    console.log(data)

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (   

      <div className="column is-6-tablet is-3-desktop"  key={post.id} >
        <div className="card" style={{height: '100%'}}>
          <div className="card-image"><a href={post.fields.slug}><img className= "lazy"  style={{height: '200px',display: 'block',
marginLeft: 'auto',
  marginRight: 'auto'}} src={post.frontmatter.featuredimage.childImageSharp.fluid.src} alt={post.frontmatter.title}></img></a></div>
          <div className="card-content">
            <div className="block"><small className="is-size-7">{post.frontmatter.date} | By {post.frontmatter.author}</small></div>
            <h5 className="title is-6"><a href={post.fields.slug}>{post.frontmatter.title}</a></h5>
            <p>{post.excerpt}</p>
          </div>
        </div>
     
    
 </div>
          ))}
      </div>
    )

  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                author
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 256, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)


