/* eslint-disable no-undef */
import React,{useState} from 'react'
import PropTypes from 'prop-types'
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import Background from '../img/expresscv_background.svg'
import resumeImage from '../img/resume.svg'




export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  helmet


}) => {


  return (

<div className="content is-small"   style={{
              margin: '1rem',
            }}>
            {helmet || ""}
            <div className ="columns " style={{  
  backgroundImage:`url(${Background})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  position: 'left'

}}>
   <div className= "column" style={{  
  height:'auto'
}}>         
    <figure className="image is-3by1 is-hidden-mobile" style={{  

}}>
    <img className= "lazy" alt='expresscv hero' src = {resumeImage} >
    </img>
     </figure>
     </div>
     <div className= "column zenhero"  style={{  
  margin:'auto'
}}> 
      <h1>ExpressCV Guides </h1>
<ul>
    <li>Easy-to-follow, guides you through creating your CV</li>
    <li>CV templates, writing samples and tips</li>
    <li>Your ultimate resource for all things CV, cover letter, and resume-related</li>
</ul>
    <a class="button is-danger" rel="noopener noreferrer" href= "https://www.livecareer.com/build-resume">
    CREATE A CV</a>
</div>
  </div>
<hr></hr>  
<h2  style={{  
   textAlign: 'center',
    color : '#3273dc'
}}>ExpressCV Blog</h2>  
  <hr></hr>  
<BlogRoll/>

  
  </div>
 

)
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  helmet: PropTypes.object
}

const IndexPage = ({ data }) => {
   const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        helmet={
          <Helmet>
<link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png"/>
<link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png"/>
<link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
<link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
<link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
<link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
<link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
<link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
<link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
<link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png"/>
<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
<link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
<link rel="manifest" href="/manifest.json"/>
<meta name="msapplication-TileColor" content="#ffffff"/>
<meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
<meta name="theme-color" content="#ffffff"/>

            <title>{`${frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${frontmatter.description}`}
            />
            <meta property="og:type" content="article"/>
            <meta property="og:url" content="https://express-cv.com/"/>
            <meta property="og:title" content={`${frontmatter.title}`}/>
            <meta property="og:description" content={`${frontmatter.description}`}/>
            <meta property="og:image" content=""/>
          
          </Helmet>
        }
      />
      
    </Layout>

  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
        heading
        subheading
        mainpitch {
          title
          description
        }
       
      }
    }
  }
`
